import { UPDATE_STATE } from './actions/updateStateAction';
import { ADD_WEEK } from './actions/addWeekAction';
import { CHANGE_RECURRENCE } from './actions/changeRecurrenceAction';
import { SET_WEEK_ACTUAL_DAY } from './actions/setWeekActualDayAction';
import { SUBTRACT_WEEK } from './actions/subtractWeekAction';
import { HANDLE_CLICK_TIME } from './actions/handleClickTimeAction';
import { GO_TO_NEXT_WEEK } from './actions/goToNextWeekAction';
import { GO_BACK_FROM_WEEK } from './actions/goBackFromWeekAction';
import { SELECT_PLACE } from './actions/selectPlaceAction';
import { SYNC_AVAILABITILIES_FROM_BACKEND } from './actions/syncAvailabitiliesFromBackendAction';
import { SYNC_UUID_FROM_BACKEND } from './actions/syncUuidFromBackendAction';
import { PlaceInterface } from 'src/pages/places/types/PlaceInterface';
import {
  AvailabilityCreateInterface,
  AvailabilityGetInterface,
} from '../../types/AvailabilityInterface';
import { UseQueryResult } from 'react-query';
import { CustomAxiosResponse } from 'src/services/axiosService';

export interface AvailabilityContextInterface {
  actualDate: Date;
  availabilities: AvailabilityCreateInterface[];
  allAvailabilities:
    | undefined
    | UseQueryResult<CustomAxiosResponse<AvailabilityGetInterface[]>, unknown>;
  places: PlaceInterface[];
  selectedPlace: PlaceInterface | undefined;
  selectedAvailabilities: string | undefined;
}

export type AvailabilityAction =
  | UPDATE_STATE
  | ADD_WEEK
  | CHANGE_RECURRENCE
  | SET_WEEK_ACTUAL_DAY
  | SUBTRACT_WEEK
  | HANDLE_CLICK_TIME
  | SYNC_UUID_FROM_BACKEND
  | GO_TO_NEXT_WEEK
  | GO_BACK_FROM_WEEK
  | SYNC_AVAILABITILIES_FROM_BACKEND
  | SELECT_PLACE;

export enum AvailabilityTypesEnum {
  UPDATE_STATE,
  CHANGE_RECURRENCE,
  ADD_WEEK,
  SET_WEEK_ACTUAL_DAY,
  SUBTRACT_WEEK,
  HANDLE_CLICK_TIME,
  SYNC_UUID_FROM_BACKEND,
  GO_BACK_FROM_WEEK,
  GO_TO_NEXT_WEEK,
  SELECT_PLACE,
  SYNC_AVAILABITILIES_FROM_BACKEND,
}
