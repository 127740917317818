import { FC, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Accessibility,
  Badge,
  Check,
  HealthAndSafety,
  PinDropOutlined,
  Work,
  WorkOutline,
  Workspaces,
} from '@material-ui/icons';

import AssignmentIcon from '@material-ui/icons/Assignment';
import Calendar from 'src/icons/Calendar';
import { useAuth } from '../../hooks/useAuth';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import Scrollbar from '../Scrollbar';
import NavSection from '../NavSection';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

export interface SectionInterface {
  title: string;
  show: boolean;
  items: Item[];
}

export interface Item {
  title: string;
  path: string;
  icon: JSX.Element;
  children?: Child[];
  show: boolean;
}

export interface Child {
  title: string;
  path: string;
  show: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user, isAdmin, isProfessional, isActive, isDenied, isNewMember } =
    useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const sections: SectionInterface[] = useMemo(
    () => [
      {
        title: 'Geral',
        show: isAdmin || isActive || isNewMember || isDenied,
        items: [
          {
            title: 'Indicadores',
            path: '/',
            show: isAdmin,
            icon: <ChartSquareBarIcon fontSize="small" />,
          },
          {
            title: 'Resumo',
            path: '/',
            show: isActive,
            icon: <ChartSquareBarIcon fontSize="small" />,
          },
          {
            title: 'Meu Perfil',
            path: `profissionais/${user?.id}/editar`,
            show: isActive || isNewMember || isDenied,
            icon: <UsersIcon fontSize="small" />,
          },
        ],
      },
      {
        title: 'Detalhes',
        show: isAdmin,
        items: [
          {
            title: 'Cadastro de atuação',
            path: 'areas-de-atuacao',
            show: isAdmin,
            icon: <Badge fontSize="small" />,
            children: [
              {
                title: 'Área de atuação',
                path: 'areas-de-atuacao',
                show: isAdmin,
                icon: <Work fontSize="small" />,
              },
              {
                title: 'Especialidades',
                path: '/especialidades',
                show: isAdmin,
                icon: <WorkOutline fontSize="small" />,
              },
              {
                title: 'Subespecialidades',
                path: '/sub-especialidades',
                show: isAdmin,
                icon: <Workspaces fontSize="small" />,
              },
            ],
          },
        ],
      },
      {
        title: 'horarios',
        show: isActive,
        items: [
          {
            title: 'Minha agenda',
            path: '/horarios',
            show: isActive,
            icon: <Calendar fontSize="small" />,
          },
          {
            title: 'Localizações',
            path: '/localizacoes',
            show: isActive,
            icon: <PinDropOutlined fontSize="small" />,
          },
          {
            title: 'Horários agendados',
            path: '/agenda',
            show: isActive,
            icon: <Check fontSize="small" />,
          },
        ],
      },
      {
        title: 'Administração',
        show: isAdmin,
        items: [
          {
            title: 'Conselhos',
            icon: <AssignmentIcon fontSize="small" />,
            path: 'conselhos',
            show: isAdmin,
          },
          {
            title: 'Planos de saúde',
            icon: <HealthAndSafety fontSize="small" />,
            path: 'planos-de-saude',
            show: isAdmin,
          },
          {
            title: 'Usuários',
            icon: <UsersIcon fontSize="small" />,
            path: '/usuarios',
            show: isAdmin,
          },
          {
            title: 'Pacientes',
            icon: <Accessibility fontSize="small" />,
            path: '/pacientes',
            show: isAdmin,
          },
        ],
      },
      //
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAdmin, isProfessional, user],
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/">
              <Avatar
                // @ts-ignore
                src={user?.user_avatar?.url || user?.name}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user?.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user?.role.label}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections
            .filter(({ show }) => show)
            .map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3,
                  },
                }}
                {...section}
              />
            ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
