import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import { SettingsProvider } from './contexts/SettingsContext';
import store from './store';
import AuthProvider from './contexts/AuthContext/AuthContext';
import LayoutProvider from './contexts/LayoutContext/LayoutContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <QueryClientProvider client={queryClient}>
              <SettingsProvider>
                <BrowserRouter>
                  <LayoutProvider>
                    <AuthProvider>
                      <App />
                      <ReactQueryDevtools initialIsOpen />
                    </AuthProvider>
                  </LayoutProvider>
                </BrowserRouter>
              </SettingsProvider>
            </QueryClientProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root'),
);
