import React, { createContext, useEffect, useState } from 'react';

interface PlaceProviderInterface {
  map: google.maps.Map | undefined;
  lat: string | number;
  lng: string | number;
  setMap: React.Dispatch<React.SetStateAction<any>>;
  setLat: React.Dispatch<React.SetStateAction<any>>;
  setLng: React.Dispatch<React.SetStateAction<any>>;
}

export const DEFAULT_LAT = -21.760191038451797;
export const DEFAULT_LNG = -43.35299521976365;

export const PlaceContext = createContext<PlaceProviderInterface>({
  map: undefined,
  lat: DEFAULT_LAT,
  lng: DEFAULT_LNG,
  setLat: () => console.warn('dispatch not ready'),
  setLng: () => console.warn('dispatch not ready'),
  setMap: () => console.warn('dispatch not ready'),
});

const PlaceProvider: React.FC = ({ children }) => {
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [lat, setLat] = useState<number>(DEFAULT_LAT);
  const [lng, setLng] = useState<number>(DEFAULT_LNG);

  useEffect(() => {
    console.log('map', map);
    console.log('lat', lat);
    console.log('lng', lng);
    map?.panTo({ lat, lng });
  }, [lat, lng, map]);

  const globals = {
    map,
    setMap,
    lat,
    setLat,
    lng,
    setLng,
  };

  return (
    <PlaceContext.Provider value={globals}>{children}</PlaceContext.Provider>
  );
};

export default PlaceProvider;
