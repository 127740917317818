import { endOfWeek, min, set, startOfWeek, subWeeks } from 'date-fns';
import { DEFAULT_LOCALE } from 'src/pages/availability/hooks/useAvailability';
import { AvailabilityCreateInterface } from 'src/pages/availability/types/AvailabilityInterface';
import { getAvailabilities } from 'src/pages/availability/utils/getAvailabilities';
import { getSelectedAvailabilities } from 'src/pages/availability/utils/getSelectedAvailabilities';
import {
  AvailabilityContextInterface,
  AvailabilityTypesEnum,
} from '../AvailabilityInterface';

export type SUBTRACT_WEEK = {
  type: AvailabilityTypesEnum.SUBTRACT_WEEK;
};

export function subtractWeekAction(
  state: AvailabilityContextInterface,
  action: SUBTRACT_WEEK,
): AvailabilityContextInterface {
  const selectedAvailability = getSelectedAvailabilities(state);

  if (!selectedAvailability) return state;

  const dateArr = state.availabilities
    .filter(({ place }) => place === selectedAvailability.place)
    .map(({ start_week_day }) => start_week_day);

  const firstDate = min(dateArr);

  const options = { hours: 0, milliseconds: 0, minutes: 0, seconds: 0 };
  const _startOfWeek = startOfWeek(firstDate);
  const _endOfWeek = endOfWeek(firstDate);

  const newObj: AvailabilityCreateInterface = {
    times: [],
    uuid: undefined,
    recurrence: selectedAvailability?.recurrence || 30,
    place: state.selectedPlace!.uuid,
    start_week_day: set(subWeeks(_startOfWeek, 1), options),
    end_week_day: set(subWeeks(_endOfWeek, 1), options),
  };

  return {
    ...state,
    availabilities: [newObj, ...state.availabilities],
  };
}
