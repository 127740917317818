import { isEqual, parseISO } from 'date-fns';
import { getAvailabilities } from 'src/pages/availability/utils/getAvailabilities';
import { getSelectedAvailabilities } from 'src/pages/availability/utils/getSelectedAvailabilities';
import { AvailabilityGetInterface } from '../../../types/AvailabilityInterface';
import { 
  AvailabilityContextInterface,
  AvailabilityTypesEnum
} from '../AvailabilityInterface';

export type SYNC_UUID_FROM_BACKEND = {
  type: AvailabilityTypesEnum.SYNC_UUID_FROM_BACKEND;
  response: AvailabilityGetInterface;
};

export function syncUuidFromBackendAction(
  state: AvailabilityContextInterface,
  action: SYNC_UUID_FROM_BACKEND,
): AvailabilityContextInterface {
  const now = new Date();
  const selected = getSelectedAvailabilities(state);

  return {
    ...state,
    availabilities: state.availabilities.map((availability) => {
      if (!selected?.start_week_day) return availability;
      if (availability.place !== selected.place) return availability;

      return isEqual(availability.start_week_day, (selected?.start_week_day || now))
        ? { ...availability, uuid: action.response.uuid, times: action.response.times.map(({ time }) => parseISO(time))}
        : availability;
    }
    )
  };
}