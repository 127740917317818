import React, { createContext, useEffect, useReducer } from 'react';
import { useQuery } from 'react-query';
import { getAllPlaces } from 'src/pages/places/services/getAllPlaces';
import Reducer from './AvailabilityReducer';
import { availabilityInitialState } from './AvailabilityInitializer';
import {
  AvailabilityContextInterface,
  AvailabilityAction,
  AvailabilityTypesEnum,
} from './AvailabilityInterface';
import { getAllAvailabilities } from '../../services/getAllAvailabilities';

export type AvailabilityDispatchType = React.Dispatch<AvailabilityAction>;

interface AvailabilityProviderInterface {
  stateAvailability: AvailabilityContextInterface;
  dispatchAvailability: AvailabilityDispatchType;
}

export const AvailabilityContext = createContext<AvailabilityProviderInterface>(
  {
    stateAvailability: availabilityInitialState,
    dispatchAvailability: () => console.warn('AvailabilityDispatch not ready'),
  },
);

const AvailabilityProvider: React.FC = ({ children }) => {
  const [stateAvailability, dispatchAvailability] = useReducer(
    Reducer,
    availabilityInitialState,
  );

  const places = useQuery('getAllPlaces', getAllPlaces);
  const availabilitiesQuery = useQuery(
    'getAllAvailabilities',
    getAllAvailabilities,
  );

  useEffect(() => {
    dispatchAvailability({
      type: AvailabilityTypesEnum.UPDATE_STATE,
      newState: { places: places.data?.data || [] },
    });
  }, [places.data]);

  useEffect(() => {
    dispatchAvailability({
      type: AvailabilityTypesEnum.UPDATE_STATE,
      newState: { allAvailabilities: availabilitiesQuery },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilitiesQuery.data]);

  const globals = {
    stateAvailability,
    dispatchAvailability,
  };

  return (
    <AvailabilityContext.Provider value={globals}>
      {children}
    </AvailabilityContext.Provider>
  );
};

export default AvailabilityProvider;
