import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
// @ts-ignore
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const Logo: FC<LogoProps> = () => (
  <img
    alt="Logo Encontré"
    src="/static/icons/logo2.png"
    height="52px"
    width="auto"
  />
);

export default Logo;
