import React from 'react';
import { Outlet } from 'react-router-dom';
import AvailabilityProvider from './contexts/AvailabilityContext/AvailabilityContext';
import { ProfessionalGuard } from '../users/guards/ProfessionalGuard';

interface AvailabilityRootPageInterface {}

const AvailabilityRootPage: React.FC<AvailabilityRootPageInterface> = () => (
  <ProfessionalGuard>
    <AvailabilityProvider>
      <Outlet />
    </AvailabilityProvider>
  </ProfessionalGuard>
);

export default AvailabilityRootPage;
