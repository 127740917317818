import { endOfWeek, isEqual, set, startOfWeek } from 'date-fns';
import { PlaceInterface } from 'src/pages/places/types/PlaceInterface';
import { DEFAULT_LOCALE } from 'src/pages/availability/hooks/useAvailability';
import { AvailabilityCreateInterface } from 'src/pages/availability/types/AvailabilityInterface';
import { getAvailabilities } from 'src/pages/availability/utils/getAvailabilities';
import { getSelectedAvailabilities } from 'src/pages/availability/utils/getSelectedAvailabilities';
import { DEFAULT_AVAILABITILIES } from '../AvailabilityInitializer';
import {
  AvailabilityContextInterface,
  AvailabilityTypesEnum,
} from '../AvailabilityInterface';

export type HANDLE_CLICK_TIME = {
  type: AvailabilityTypesEnum.HANDLE_CLICK_TIME;
  date: Date;
};

export function getNewAvailability(
  actualDate: Date,
  selectedPlace: PlaceInterface | undefined,
): AvailabilityCreateInterface {
  const options = { hours: 0, milliseconds: 0, minutes: 0, seconds: 0 };
  const _startOfWeek = startOfWeek(actualDate);
  const _endOfWeek = endOfWeek(actualDate);

  return {
    ...DEFAULT_AVAILABITILIES,
    start_week_day: set(_startOfWeek, options),
    end_week_day: set(_endOfWeek, options),
    place: selectedPlace?.uuid,
  };
}

export function handleClickTimeAction(
  state: AvailabilityContextInterface,
  action: HANDLE_CLICK_TIME,
): AvailabilityContextInterface {
  const hasAvailabilities = !!getSelectedAvailabilities(state);

  if (!hasAvailabilities) {
    const newAvailability = getNewAvailability(
      state.actualDate,
      state.selectedPlace,
    );
    state.availabilities.push(newAvailability);
  }

  const selectedAvailability = getSelectedAvailabilities(state);

  const arr = state.availabilities.map((availability) => {
    if (selectedAvailability?.place !== availability.place) return availability;
    if (!selectedAvailability?.start_week_day) return availability;
    if (
      !isEqual(availability.start_week_day, selectedAvailability.start_week_day)
    )
      return availability;

    const hasTime = !!availability.times.find((time) =>
      isEqual(time, action.date),
    );
    const arrWithoutTime = availability.times.filter(
      (time) => !isEqual(time, action.date),
    );
    const timesArr = hasTime
      ? arrWithoutTime
      : [...arrWithoutTime, action.date];

    return {
      ...availability,
      times: timesArr,
    };
  });

  return {
    ...state,
    availabilities: arr,
  };
}
