import { AvailabilityCreateInterface } from '../../types/AvailabilityInterface';
// eslint-disable-next-line import/no-cycle
import { AvailabilityContextInterface } from './AvailabilityInterface';

export const DEFAULT_RECURRENCE = 30;
export const DEFAULT_START_WEEK_DAY = new Date();
export const DEFAULT_END_WEEK_DAY = new Date();

export const DEFAULT_AVAILABITILIES: AvailabilityCreateInterface = {
  start_week_day: DEFAULT_START_WEEK_DAY,
  end_week_day: DEFAULT_END_WEEK_DAY,
  recurrence: DEFAULT_RECURRENCE,
  times: [],
  place: undefined,
};

export const availabilityInitialState: AvailabilityContextInterface = {
  actualDate: new Date(),
  selectedPlace: undefined,
  places: [],
  availabilities: [],
  selectedAvailabilities: undefined,
  allAvailabilities: undefined,
};
