import {
  addWeeks,
  endOfWeek,
  max,
  set,
  setHours,
  setMilliseconds,
  setMinutes,
  startOfWeek,
} from 'date-fns';
import { DEFAULT_LOCALE } from 'src/pages/availability/hooks/useAvailability';
import { AvailabilityCreateInterface } from 'src/pages/availability/types/AvailabilityInterface';
import { getSelectedAvailabilities } from 'src/pages/availability/utils/getSelectedAvailabilities';
import {
  AvailabilityContextInterface,
  AvailabilityTypesEnum,
} from '../AvailabilityInterface';

export type ADD_WEEK = {
  type: AvailabilityTypesEnum.ADD_WEEK;
};

export function addWeekAction(
  state: AvailabilityContextInterface,
  action: ADD_WEEK,
): AvailabilityContextInterface {
  const selectedAvailability = getSelectedAvailabilities(state);

  if (!selectedAvailability) return state;

  const dateArr = state.availabilities
    .filter(({ place }) => place === selectedAvailability.place)
    .map(({ start_week_day }) => start_week_day);

  const lastDate = max(dateArr);

  const options = { hours: 0, milliseconds: 0, minutes: 0, seconds: 0 };
  const _startOfWeek = startOfWeek(lastDate);
  const _endOfWeek = endOfWeek(lastDate);

  const newObj: AvailabilityCreateInterface = {
    times: [],
    uuid: undefined,
    recurrence: selectedAvailability?.recurrence || 30,
    place: state.selectedPlace!.uuid,
    start_week_day: set(addWeeks(_startOfWeek, 1), options),
    end_week_day: set(addWeeks(_endOfWeek, 1), options),
  };

  return {
    ...state,
    availabilities: [...state.availabilities, newObj],
  };
}
