import { parseISO } from 'date-fns'
import {
  axiosGET,
  CustomAxiosResponse
} from '../../../services/axiosService'
import { AvailabilityGetInterface, AvailabilityInterface } from '../types/AvailabilityInterface'

interface AvailabilityResponseInterface extends Omit<AvailabilityGetInterface, 'start_week_day' | 'end_week_day'> {
  start_week_day: string;
  end_week_day: string;
}

export const getAllAvailabilities = async (): Promise<CustomAxiosResponse<
  AvailabilityGetInterface[]
>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/availabilities`

    const response = await axiosGET<AvailabilityResponseInterface[]>(endpoint)

    const data: AvailabilityGetInterface[] = response.data.map(availability => ({
      ...availability,
      end_week_day: parseISO(availability.end_week_day),
      start_week_day: parseISO(availability.start_week_day),
    }));

    return {
      ...response,
      data,
      message: 'Pronto!'
    }
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.'
    )
  }
}
