export enum UserRolesEnum {
  Admin = 'admin',
  Professional = 'user',
}

export interface RoleInterface {
  id: string;
  slug: UserRolesEnum;
  label: string;
  created_at: Date;
  updated_at: Date;
}
