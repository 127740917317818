import { isEqual } from 'date-fns';
import { getAvailabilities } from 'src/pages/availability/utils/getAvailabilities';
import { getSelectedAvailabilities } from 'src/pages/availability/utils/getSelectedAvailabilities';
import { 
  AvailabilityContextInterface,
  AvailabilityTypesEnum
} from '../AvailabilityInterface';
import { getNewAvailability } from './handleClickTimeAction';

export type CHANGE_RECURRENCE = {
  type: AvailabilityTypesEnum.CHANGE_RECURRENCE;
  newRecurrence: number;
};

export function changeRecurrenceAction(
  state: AvailabilityContextInterface,
  action: CHANGE_RECURRENCE,
): AvailabilityContextInterface {
  const hasAvailabilities = !!getSelectedAvailabilities(state);

  if (!hasAvailabilities) {
    const newAvailability = getNewAvailability(state.actualDate, state.selectedPlace);
    state.availabilities.push(newAvailability);
  }
  
  const selectedAvailability = getSelectedAvailabilities(state);

  const arr = state.availabilities.map((availability) => {
    if (!selectedAvailability?.start_week_day) return availability;
    if (availability.place !== selectedAvailability.place) return availability;

    return isEqual(availability.start_week_day, selectedAvailability.start_week_day)
      ? { ...availability, recurrence: action.newRecurrence }
      : availability;
    }
  );

  return {
    ...state,
    availabilities: arr
  };
}