import { SET_FIREBASE_USER, AuthContextInterface } from '../AuthInterface';

export function setFirebaseUserAction(
  state: AuthContextInterface,
  action: SET_FIREBASE_USER,
): AuthContextInterface {
  return {
    ...state,
    firebaseUser: action.firebaseUser,
  };
}
