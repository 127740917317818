import { UserInterface } from '../pages/users/types/UserInterface';
import { axiosGET, CustomAxiosResponse } from './axiosService';

export const getLoggedUser = async (): Promise<
  CustomAxiosResponse<UserInterface>
> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/users/me`;

    const response = await axiosGET<UserInterface>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
