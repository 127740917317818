import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
  LoginJWT,
} from '../../components/authentication/login';
import Logo from '../../components/Logo';
import { useAuth } from '../../hooks/useAuth';
import gtm from '../../lib/gtm';

const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  Firebase: '/static/icons/firebase.svg',
  JWT: '/static/icons/logo1.svg',
};

const Login: FC = () => {
  const { method } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | encontré </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{ py: '80px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Login
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Acesse a área restrita
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '250%',
                      width: 'auto',
                      marginRight: 4,
                    },
                  }}
                >
                  {/* @ts-ignore */}
                  <img alt="Auth platform" src={platformIcons[method]} />
                </Box>
              </Box>
              <Box
                mb={1}
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                {method === 'Amplify' && <LoginAmplify />}
                {method === 'Auth0' && <LoginAuth0 />}
                {method === 'Firebase' && <LoginFirebase />}
                {method === 'JWT' && <LoginJWT />}
                <br />
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => navigate('/autenticacao/password-recovery')}
                >
                  Esqueci minha senha
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
