import { getLoggedUser } from './../../../services/getLoggedUser';
import { User } from './../../../types/user';
import { listUserInfo } from './listUserInfo';
import {
  CustomAxiosResponse,
  VariantEnum,
  axiosPOST,
  AUTH_TOKEN,
} from './../../../services/axiosService';
import { UserInterface } from 'src/pages/users/types/UserInterface';

export interface LoginInterface {
  token: string;
}

export interface LoginResponseInterface {
  token: string;
  user: UserInterface;
  firebaseUser?: any;
}

export const login = async (
  tokenFirebase: string,
): Promise<CustomAxiosResponse<LoginResponseInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/auth/login`;

    const body = {
      firebase_token: tokenFirebase,
      origin: 'backoffice'
    };

    const response = await axiosPOST<LoginInterface>(endpoint, body, undefined);

    window.localStorage.setItem(AUTH_TOKEN, response.data.token);

    const userResponse = await getLoggedUser();

    return {
      ...userResponse,
      data: {
        token: response.data.token,
        user: userResponse.data,
      },
      message: `Bem vindo ${userResponse.data.name}!`,
      variant: VariantEnum.success,
    };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, ao criar o usuário.',
    );
  }
};
