import { 
  AvailabilityContextInterface,
  AvailabilityTypesEnum
} from '../AvailabilityInterface';

export type UPDATE_STATE = {
  type: AvailabilityTypesEnum.UPDATE_STATE;
  newState: Partial<AvailabilityContextInterface>;
};

export function updateStateAction(
  state: AvailabilityContextInterface,
  action: UPDATE_STATE,
): AvailabilityContextInterface {
  return {
    ...state,
    ...action.newState
  };
}