import { User } from 'src/types/user';
import { 
  AuthContextInterface,
  AuthTypesEnum
} from '../AuthInterface';

export type UPDATE_LOGGED_USER = {
  type: AuthTypesEnum.UPDATE_LOGGED_USER;
  user: User;
};

export function updateLoggedUserAction(
  state: AuthContextInterface,
  action: UPDATE_LOGGED_USER,
): AuthContextInterface {
  return {
    ...state,
    loggedUser: {
      ...state.loggedUser,
      ...action.user
    },
  };
}