import React from 'react';
import { Outlet } from 'react-router-dom';
import { ProfessionalGuard } from '../users/guards/ProfessionalGuard';
import PlaceProvider from './contexts/PlaceContext';

interface PlacesRootPageInterface {}

const PlacesRootPage: React.FC<PlacesRootPageInterface> = () => (
  <PlaceProvider>
    <ProfessionalGuard>
      <Outlet />
    </ProfessionalGuard>
  </PlaceProvider>
);

export default PlacesRootPage;
