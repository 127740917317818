import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';

interface ProfessionalGuardInterface {
  children: ReactNode;
}

export const ProfessionalGuard: FC<ProfessionalGuardInterface> = ({ children }) => {
  const { isProfessional } = useAuth();

  if (!isProfessional) {
    return <Navigate to="/401" />;
  }

  return <>{children}</>;
};

export default ProfessionalGuard;
