import { parseISO } from 'date-fns';
import {
  AvailabilityCreateInterface,
  AvailabilityGetInterface,
} from 'src/pages/availability/types/AvailabilityInterface';
import {
  AvailabilityContextInterface,
  AvailabilityTypesEnum,
} from '../AvailabilityInterface';

export type SYNC_AVAILABITILIES_FROM_BACKEND = {
  type: AvailabilityTypesEnum.SYNC_AVAILABITILIES_FROM_BACKEND;
  response: AvailabilityGetInterface[];
};

export function syncAvailabitiliesFromBackendAction(
  state: AvailabilityContextInterface,
  action: SYNC_AVAILABITILIES_FROM_BACKEND,
): AvailabilityContextInterface {
  const newAvailabilitiesArr: AvailabilityCreateInterface[] =
    action.response.map((backAvailability) => {
      const stateAvailability = state.availabilities.find(
        ({ uuid }) => backAvailability.uuid === uuid,
      );

      const response: AvailabilityCreateInterface =
        stateAvailability === undefined
          ? {
              ...backAvailability,
              start_week_day: backAvailability.start_week_day,
              end_week_day: backAvailability.end_week_day,
              place: backAvailability.place.uuid,
              uuid: backAvailability.uuid,
              times: backAvailability.times.map(({ time }) => parseISO(time)),
            }
          : stateAvailability;

      return response;
    });

  return {
    ...state,
    availabilities: newAvailabilitiesArr,
  };
}
