import React from 'react';
import { Outlet } from 'react-router-dom';

interface ProfessionalsRootPageInterface {}

const ProfessionalsRootPage: React.FC<ProfessionalsRootPageInterface> = () => (
  <Outlet />
);

export default ProfessionalsRootPage;
