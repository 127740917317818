import { PlaceUUID } from 'src/pages/places/types/PlaceInterface';
import { AvailabilityCreateInterface } from 'src/pages/availability/types/AvailabilityInterface';
import { getAvailabilities } from 'src/pages/availability/utils/getAvailabilities';
import { DEFAULT_AVAILABITILIES } from '../AvailabilityInitializer';
import { 
  AvailabilityContextInterface,
  AvailabilityTypesEnum
} from '../AvailabilityInterface';

export type SELECT_PLACE = {
  type: AvailabilityTypesEnum.SELECT_PLACE;
  uuid: PlaceUUID;
};

export function selectPlaceAction(
  state: AvailabilityContextInterface,
  action: SELECT_PLACE,
): AvailabilityContextInterface {
  const selectedPlace = state.places.find(({ uuid }) => action.uuid === uuid);

  return {
    ...state,
    selectedPlace,
  };
}