import { addWeeks } from 'date-fns';
import { getSelectedAvailabilities } from 'src/pages/availability/utils/getSelectedAvailabilities';
import { 
  AvailabilityContextInterface,
  AvailabilityTypesEnum
} from '../AvailabilityInterface';
import { addWeekAction } from './addWeekAction';

export type GO_TO_NEXT_WEEK = {
  type: AvailabilityTypesEnum.GO_TO_NEXT_WEEK;
};

export function goToNextWeekAction(
  state: AvailabilityContextInterface,
  action: GO_TO_NEXT_WEEK,
): AvailabilityContextInterface {
  const actualNewDate = addWeeks(state.actualDate, 1);
  
  const stateWithAddedWeek: AvailabilityContextInterface = {
    ...state,
    actualDate: actualNewDate
  }

  const selectedAvailability = getSelectedAvailabilities(stateWithAddedWeek);
  
  if (!selectedAvailability) {
    const newState = addWeekAction(state, { type: AvailabilityTypesEnum.ADD_WEEK });
    return {
      ...newState,
      actualDate: actualNewDate
    };
  }

  return stateWithAddedWeek;
}