import { compareAsc, compareDesc } from 'date-fns';
import { AvailabilityContextInterface } from '../contexts/AvailabilityContext/AvailabilityInterface';
import {
  AvailabilityCreateInterface,
  AvailabilityGetInterface,
} from '../types/AvailabilityInterface';
import { getAvailabilities } from './getAvailabilities';

export function checkIfAvailabilityIsInsideWeek(
  availability: AvailabilityCreateInterface | AvailabilityGetInterface,
  actualDate: Date,
) {
  const isAfterStart = compareAsc(actualDate, availability.start_week_day);
  const isBeforeEnd = compareDesc(actualDate, availability.end_week_day);

  return (
    (isAfterStart === 0 || isAfterStart === 1) &&
    (isBeforeEnd === 0 || isBeforeEnd === 1)
  );
}

export function getSelectedAvailabilities(
  stateAvailability: AvailabilityContextInterface,
): AvailabilityCreateInterface | undefined {
  const selectedAvailabilities = getAvailabilities(stateAvailability);

  const selected = selectedAvailabilities.find((availability) =>
    checkIfAvailabilityIsInsideWeek(availability, stateAvailability.actualDate),
  );

  return selected;
}
