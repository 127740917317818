import {
  AvailabilityAction,
  AvailabilityContextInterface,
  AvailabilityTypesEnum,
} from './AvailabilityInterface';
import { updateStateAction } from './actions/updateStateAction'
import { addWeekAction } from './actions/addWeekAction';
import { changeRecurrenceAction } from './actions/changeRecurrenceAction';
import { setWeekActualDayAction } from './actions/setWeekActualDayAction';
import { subtractWeekAction } from './actions/subtractWeekAction';
import { handleClickTimeAction } from './actions/handleClickTimeAction';
import { selectPlaceAction } from './actions/selectPlaceAction';
import { syncUuidFromBackendAction } from './actions/syncUuidFromBackendAction';
import { goToNextWeekAction } from './actions/goToNextWeekAction';
import { goBackFromWeekAction } from './actions/goBackFromWeekAction';
import { syncAvailabitiliesFromBackendAction } from './actions/syncAvailabitiliesFromBackendAction';

function updateStateReducer(state: AvailabilityContextInterface, action: AvailabilityAction): AvailabilityContextInterface {
  switch (action.type) {
    case AvailabilityTypesEnum.UPDATE_STATE:
      return updateStateAction(state, action);
    
      case AvailabilityTypesEnum.ADD_WEEK:
      return addWeekAction(state, action);
    
      case AvailabilityTypesEnum.CHANGE_RECURRENCE:
      return changeRecurrenceAction(state, action);
    
      case AvailabilityTypesEnum.SET_WEEK_ACTUAL_DAY:
      return setWeekActualDayAction(state, action);
    
      case AvailabilityTypesEnum.SUBTRACT_WEEK:
        return subtractWeekAction(state, action);

      case AvailabilityTypesEnum.HANDLE_CLICK_TIME:
        return handleClickTimeAction(state, action);

      case AvailabilityTypesEnum.SELECT_PLACE:
        return selectPlaceAction(state, action);

      case AvailabilityTypesEnum.SYNC_UUID_FROM_BACKEND:
        return syncUuidFromBackendAction(state, action);

      case AvailabilityTypesEnum.SYNC_AVAILABITILIES_FROM_BACKEND:
        return syncAvailabitiliesFromBackendAction(state, action);

      case AvailabilityTypesEnum.GO_TO_NEXT_WEEK:
        return goToNextWeekAction(state, action);
    
      case AvailabilityTypesEnum.GO_BACK_FROM_WEEK:
        return goBackFromWeekAction(state, action);

    default:
      return state;
  }
}

export default updateStateReducer;
