import React from 'react';
import { Outlet } from 'react-router-dom';

interface SpecialtiesRootPageInterface {}

const SpecialtiesRootPage: React.FC<SpecialtiesRootPageInterface> = () => (
  <Outlet />
);

export default SpecialtiesRootPage;
