import { Suspense, lazy, LazyExoticComponent } from 'react';
// @ts-ignore
import type { PartialRouteObject } from 'react-router';

import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import Home from './icons/Home';
import Checkout from './pages/Checkout';

import Redirect from './utils/Redirect';

import SpecialtiesRootPage from './pages/specialties/SpecialtiesRootPage';
import PatientsRootPage from './pages/patients/PatientsRootPage';
import ProfessionalsRootPage from './pages/professionals/ProfessionalsRootPage';
import UsersRootPage from './pages/users/UsersRootPage';
import AvailabilityRootPage from './pages/availability/AvailabilityRootPage';
import PlacesRootPage from './pages/places/PlacesRootPage';
import BoardsRootPage from './pages/boards/BoardsRootPage';
import HealthPlansRootPage from './pages/healthPlans/HealthPlansRootPage';
import OccupationsRootPage from './pages/occupations/OccupationsRootPage';
import SubSpecialtiesRootPage from './pages/subSpecialties/SubSpecialtiessRootPage';

const Loadable =
  (Component: LazyExoticComponent<React.FC<{}>>) => (props: any) =>
    (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );

// Authentication

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery')),
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset')),
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/Register')),
);
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/VerifyCode')),
);
const VerifyEmail = Loadable(
  lazy(() => import('./pages/authentication/VerifyEmail')),
);

// Availability
const Availability = Loadable(
  lazy(() => import('./pages/availability/AvailabilityPage')),
);

// Dashboard

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

// User
const UserDetails = Loadable(lazy(() => import('./pages/users/UserDetails')));
const UserEdit = Loadable(lazy(() => import('./pages/users/UserEdit')));
const UserCreate = Loadable(lazy(() => import('./pages/users/UserCreate')));
const UserList = Loadable(lazy(() => import('./pages/users/UserList')));

// Places
const PlaceDetails = Loadable(
  lazy(() => import('./pages/places/PlaceDetails')),
);
const PlaceEdit = Loadable(lazy(() => import('./pages/places/PlaceEdit')));
const PlaceCreate = Loadable(lazy(() => import('./pages/places/PlaceCreate')));
const PlaceList = Loadable(lazy(() => import('./pages/places/PlaceList')));

// Boards (Conselhos)
const BoardDetails = Loadable(
  lazy(() => import('./pages/boards/BoardDetails')),
);
const BoardCreate = Loadable(lazy(() => import('./pages/boards/BoardCreate')));
const BoardList = Loadable(lazy(() => import('./pages/boards/BoardList')));
const BoardEdit = Loadable(lazy(() => import('./pages/boards/BoardEdit')));

// Professional

const ProfessionalEdit = Loadable(
  lazy(() => import('./pages/professionals/ProfessionalEdit')),
);

// Specialty

const SpecialtyDetails = Loadable(
  lazy(() => import('./pages/specialties/SpecialtyDetails')),
);
const SpecialtyEdit = Loadable(
  lazy(() => import('./pages/specialties/SpecialtyEdit')),
);
const SpecialtyCreate = Loadable(
  lazy(() => import('./pages/specialties/SpecialtyCreate')),
);
const SpecialtyList = Loadable(
  lazy(() => import('./pages/specialties/SpecialtyList')),
);

// subSpecialty

const SubSpecialtiesDetails = Loadable(
  lazy(() => import('./pages/subSpecialties/SubSpecialtiesDetails')),
);
const SubSpecialtiesEdit = Loadable(
  lazy(() => import('./pages/subSpecialties/SubSpecialtiesEdit')),
);
const SubSpecialtiesCreate = Loadable(
  lazy(() => import('./pages/subSpecialties/SubSpecialtiesCreate')),
);
const SubSpecialtiesList = Loadable(
  lazy(() => import('./pages/subSpecialties/SubSpecialtiesList')),
);

// HealthPlan

const HealthPlanDetails = Loadable(
  lazy(() => import('./pages/healthPlans/HealthPlanDetails')),
);
const HealthPlanEdit = Loadable(
  lazy(() => import('./pages/healthPlans/HealthPlanEdit')),
);
const HealthPlanCreate = Loadable(
  lazy(() => import('./pages/healthPlans/HealthPlanCreate')),
);
const HealthPlanList = Loadable(
  lazy(() => import('./pages/healthPlans/HealthPlanList')),
);

// Occupations

const OccupationDetails = Loadable(
  lazy(() => import('./pages/occupations/OccupationDetails')),
);
const OccupationEdit = Loadable(
  lazy(() => import('./pages/occupations/OccupationEdit')),
);
const OccupationCreate = Loadable(
  lazy(() => import('./pages/occupations/OccupationCreate')),
);
const OccupationList = Loadable(
  lazy(() => import('./pages/occupations/OccupationList')),
);

// Patient

const PatientDetails = Loadable(
  lazy(() => import('./pages/patients/PatientDetails')),
);

const PatientList = Loadable(
  lazy(() => import('./pages/patients/PatientList')),
);

// Schedule
const ScheduleList = Loadable(
  lazy(() => import('./pages/schedule/ScheduleSection')),
);

// Error

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired')),
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Routes

const routes: PartialRouteObject[] = [
  {
    path: 'autenticacao',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'registrar',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'register-unguarded',
        element: <Register />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
      {
        path: 'verificar-email',
        element: <VerifyEmail />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'horarios',
        element: <AvailabilityRootPage />,
        children: [
          {
            path: '',
            element: <Availability />,
          },
        ],
      },
      {
        path: 'localizacoes',
        element: <PlacesRootPage />,
        children: [
          {
            path: '',
            element: <PlaceList />,
          },
          {
            path: 'novo',
            element: <PlaceCreate />,
          },
          {
            path: ':placeId',
            element: <PlaceDetails />,
          },
          {
            path: ':placeId/editar',
            element: <PlaceEdit />,
          },
        ],
      },
      {
        path: 'conselhos',
        element: <BoardsRootPage />,
        children: [
          {
            path: '',
            element: <BoardList />,
          },
          {
            path: 'novo',
            element: <BoardCreate />,
          },
          {
            path: ':boardId',
            element: <BoardDetails />,
          },
          {
            path: ':boardId/editar',
            element: <BoardEdit />,
          },
        ],
      },
      {
        path: 'usuarios',
        element: <UsersRootPage />,
        children: [
          {
            path: '',
            element: <UserList />,
          },
          {
            path: 'novo',
            element: <UserCreate />,
          },
          {
            path: ':userId',
            element: <UserDetails />,
          },
          {
            path: ':userId/editar',
            element: <UserEdit />,
          },
        ],
      },
      {
        path: 'agenda',
        element: <AvailabilityRootPage />,
        children: [
          {
            path: '',
            element: <ScheduleList />,
          },
        ],
      },
      {
        path: 'profissionais',
        element: <ProfessionalsRootPage />,
        children: [
          {
            path: ':userId/editar',
            element: <ProfessionalEdit />,
          },
        ],
      },
      {
        path: 'especialidades',
        element: <SpecialtiesRootPage />,
        children: [
          {
            path: '',
            element: <SpecialtyList />,
          },
          {
            path: 'nova',
            element: <SpecialtyCreate />,
          },
          {
            path: ':specialtyId',
            element: <SpecialtyDetails />,
          },
          {
            path: ':specialtyId/editar',
            element: <SpecialtyEdit />,
          },
        ],
      },
      {
        path: 'sub-especialidades',
        element: <SubSpecialtiesRootPage />,
        children: [
          {
            path: '',
            element: <SubSpecialtiesList />,
          },
          {
            path: 'novo',
            element: <SubSpecialtiesCreate />,
          },
          {
            path: ':subSpecialtiesId',
            element: <SubSpecialtiesDetails />,
          },
          {
            path: ':subSpecialtiesId/editar',
            element: <SubSpecialtiesEdit />,
          },
        ],
      },
      {
        path: 'planos-de-saude',
        element: <HealthPlansRootPage />,
        children: [
          {
            path: '',
            element: <HealthPlanList />,
          },
          {
            path: 'novo',
            element: <HealthPlanCreate />,
          },
          {
            path: ':healthPlanId',
            element: <HealthPlanDetails />,
          },
          {
            path: ':healthPlanId/editar',
            element: <HealthPlanEdit />,
          },
        ],
      },
      {
        path: 'areas-de-atuacao',
        element: <OccupationsRootPage />,
        children: [
          {
            path: '',
            element: <OccupationList />,
          },
          {
            path: 'novo',
            element: <OccupationCreate />,
          },
          {
            path: ':occupationId',
            element: <OccupationDetails />,
          },
          {
            path: ':occupationId/editar',
            element: <OccupationEdit />,
          },
        ],
      },
      {
        path: 'pacientes',
        element: <PatientsRootPage />,
        children: [
          {
            path: '',
            element: <PatientList />,
          },

          {
            path: ':patientId',
            element: <PatientDetails />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Redirect to="/" />,
      },
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'checkout',
        element: <Checkout />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
