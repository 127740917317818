import {
  axiosGET,
  CustomAxiosResponse,
} from './../../../services/axiosService';
import { PlaceInterface } from './../types/PlaceInterface';

export const getAllPlaces = async (): Promise<
  CustomAxiosResponse<PlaceInterface[]>
> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/places`;

    const response = await axiosGET<PlaceInterface[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
