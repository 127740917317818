import { AUTHENTICATE_USER, AuthContextInterface } from '../AuthInterface';

export function authenticateUserAction(
  state: AuthContextInterface,
  action: AUTHENTICATE_USER,
): AuthContextInterface {
  return {
    ...state,
    loggedUser: action.loggedUser,
    // firebaseUser: action.firebaseUser,
  };
}
