import { subWeeks } from 'date-fns';
import { getSelectedAvailabilities } from 'src/pages/availability/utils/getSelectedAvailabilities';
import { 
  AvailabilityContextInterface,
  AvailabilityTypesEnum
} from '../AvailabilityInterface';
import { subtractWeekAction } from './subtractWeekAction';

export type GO_BACK_FROM_WEEK = {
  type: AvailabilityTypesEnum.GO_BACK_FROM_WEEK;
};

export function goBackFromWeekAction(
  state: AvailabilityContextInterface,
  action: GO_BACK_FROM_WEEK,
): AvailabilityContextInterface {

  const actualNewDate = subWeeks(state.actualDate, 1);
  
  const stateWithAddedWeek: AvailabilityContextInterface = {
    ...state,
    actualDate: actualNewDate
  }

  const selectedAvailability = getSelectedAvailabilities(stateWithAddedWeek);
  
  if (!selectedAvailability) {
    const newState = subtractWeekAction(state, { type: AvailabilityTypesEnum.SUBTRACT_WEEK });
    return {
      ...newState,
      actualDate: actualNewDate
    };
  }

  return stateWithAddedWeek;

}