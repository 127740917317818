import { 
  AvailabilityContextInterface,
  AvailabilityTypesEnum
} from '../AvailabilityInterface';

export type SET_WEEK_ACTUAL_DAY = {
  type: AvailabilityTypesEnum.SET_WEEK_ACTUAL_DAY;
};

export function setWeekActualDayAction(
  state: AvailabilityContextInterface,
  action: SET_WEEK_ACTUAL_DAY,
): AvailabilityContextInterface {
  return {
    ...state,
    actualDate: new Date(),
  };

}